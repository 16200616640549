import Layout from '../components/layout/Layout'
import React, { useEffect, useState } from 'react'

const INITIAL_STATE = {
  title: '',
  description: '',
  personal: '',
  country: '',
  state: '',
  city: '',
  zip: '',
  contract: '',
  salary: '',
  directions: '',
  application: '',
  level: '',
  expiration: '',
  userId: '',
}

export default function Job(props) {
  const [data, setData] = useState({ ...INITIAL_STATE })

  //const user = props.user;
  //const db = props.firebase.getdb();

  const onSubmit = (e) => {
    e.preventDefault()
    /*
        createJob(db, user.uid, data)
            .then(jobId => {
                console.log('Job Created. ', jobId);
                window.location = '/profile';
            })
*/
  }

  const onChange = (event) => {
    let { name, value, checked, type } = event.target
    value = type === 'checkbox' ? checked : value

    setData({ ...data, [name]: value })
  }

  return (
    <>
      <Layout breadcrumbTitle="My Profile" breadcrumbActive="My Profile">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-box">
              <div className="container">
                <div className="panel-white mb-30">
                  <div className="box-padding bg-postjob">
                    <div className="row mt-30">
                      <div className="col-lg-9">
                        <form className="row form-contact" onSubmit={onSubmit}>
                          <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                              <label className="font-sm color-text-mutted mb-10">
                                Job Title
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                required
                                name="title"
                                value={data.title}
                                onChange={onChange}
                              />
                            </div>
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group">
                                <label className="font-sm color-text-mutted mb-10">
                                  Company
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  required
                                  name="company"
                                  value={data.company}
                                  onChange={onChange}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label className="font-sm color-text-mutted mb-10">
                                    Add your job description
                                  </label>
                                  <textarea
                                    className="form-control"
                                    rows={4}
                                    required
                                    name="description"
                                    value={data.description}
                                    onChange={onChange}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label className="font-sm color-text-mutted mb-10">
                                    Application Directions
                                  </label>
                                  <textarea
                                    className="form-control"
                                    rows={4}
                                    required
                                    name="directions"
                                    value={data.description}
                                    onChange={onChange}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-3">
                                <div className="form-group">
                                  <label className="font-sm color-text-mutted mb-10">
                                    Country
                                  </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="country"
                                    value={data.country}
                                    onChange={onChange}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="form-group">
                                  <label className="font-sm color-text-mutted mb-10">
                                    State
                                  </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="state"
                                    value={data.state}
                                    onChange={onChange}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label className="font-sm color-text-mutted mb-10">
                                    Application URL
                                  </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="url"
                                    value={data.country}
                                    onChange={onChange}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-3">
                                <div className="form-group">
                                  <label className="font-sm color-text-mutted mb-10">
                                    City
                                  </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="city"
                                    value={data.city}
                                    onChange={onChange}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="form-group">
                                  <label className="font-sm color-text-mutted mb-10">
                                    Zip code (Optional)
                                  </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="zip"
                                    value={data.zip}
                                    onChange={onChange}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="form-group">
                                  <label className="font-sm color-text-mutted mb-10">
                                    Industry
                                  </label>
                                  <select
                                    className="form-control"
                                    required
                                    name="contract"
                                    value={data.contract}
                                    onChange={onChange}
                                  >
                                    <option value="Remote">Remote</option>
                                    <option value="Local">Local</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="form-group">
                                  <label className="font-sm color-text-mutted mb-10">
                                    Expiration
                                  </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="expiration"
                                    value={data.expiration}
                                    onChange={onChange}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label className="font-sm color-text-mutted mb-10">
                                    Contract Type
                                  </label>
                                  <select
                                    className="form-control"
                                    required
                                    name="contract"
                                    value={data.contract}
                                    onChange={onChange}
                                  >
                                    <option value="Remote">Remote</option>
                                    <option value="Local">Local</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="form-group">
                                  <label className="font-sm color-text-mutted mb-10">
                                    City
                                  </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="city"
                                    value={data.city}
                                    onChange={onChange}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="form-group">
                                  <label className="font-sm color-text-mutted mb-10">
                                    Zip code (Optional)
                                  </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="zip"
                                    value={data.zip}
                                    onChange={onChange}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label className="font-sm color-text-mutted mb-10">
                                    Salary
                                  </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="salary"
                                    value={data.salary}
                                    onChange={onChange}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="box-button mt-15">
                              <button
                                className="btn btn-apply-big font-md font-bold"
                                type="submit"
                              >
                                Post a Job
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
