import { Collections } from '../constants/defines'
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  doc,
  updateDoc,
  getDoc,
  deleteDoc,
  setDoc,
} from 'firebase/firestore'
import { getCollectionDocuments } from './user'

const deleteJob = (db, job) => {
  return new Promise((resolve, reject) => {
    deleteDoc(doc(db, Collections.JOBS, job.id))
      .then(() => {
        console.log('Job successfully deleted!')
        resolve()
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error deleting Job: ', error)
        reject()
      })
  })
}

const createJob = async (db, uid, data) => {
  const newDocRef = doc(collection(db, Collections.JOBS))
  const jobId = newDocRef.id

  const payload = {
    ...data,
    userId: uid,
    isAdmin: true,
    scrapper: false,
    createdAt: new Date(),
    updatedAt: new Date(),
    id: jobId,
  }

  try {
    await setDoc(newDocRef, payload)
    console.log('New Job created with ID: ', jobId)
    return jobId
  } catch (err) {
    console.error('Error adding Job: ', err)
  }
}

const getJob = (db, uid) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, Collections.JOBS, uid)
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const data = { ...docSnap.data(), id: docSnap.id }
        resolve(data)
      } else {
        resolve(null)
      }
    })
  })
}

const getJobs = (db, sortBy = 'desc') => {
  return new Promise((resolve, reject) => {
    const q = query(
      collection(db, Collections.JOBS),
      orderBy('createdAt', sortBy),
    )

    return getDocs(q)
      .then((querySnapshot) => {
        let items = []

        querySnapshot.forEach((doc) => {
          items.push({ ...doc.data(), id: doc.id })
        })
        resolve(items)
      })
      .catch((error) => {
        console.log('getJobs: ', error)
        reject()
      })
  })
}

const getJobsV3 = async (db, filters, limitPerPage) => {
  return getCollectionDocuments(db, filters, limitPerPage, Collections.JOBS)
}

const getJobsByFilter = async (db, whereArray, sortBy = 'desc') => {
  const whereFilters = whereArray.map((data) =>
    where(data[0], data[1], data[2]),
  )

  try {
    const q = await query(
      collection(db, Collections.JOBS),
      ...whereFilters,
      orderBy('createdAt', sortBy),
    )
    const querySnapshot = await getDocs(q)
    let items = []
    querySnapshot.forEach((doc) => {
      items.push({ ...doc.data(), id: doc.id })
    })
    return items
  } catch (error) {
    console.log('getJobs: ', error)
  }
  return null
}

const getUserJobs = (db, userId) => {
  return new Promise((resolve, reject) => {
    const q = query(
      collection(db, Collections.JOBS),
      where('userId', '==', userId),
    )

    return getDocs(q)
      .then((querySnapshot) => {
        let items = []

        querySnapshot.forEach((doc) => {
          items.push({ ...doc.data(), id: doc.id })
        })
        resolve(items)
      })
      .catch((error) => {
        console.log('getUserJobs: ', error)
        reject()
      })
  })
}

const updateJob = (db, docId, item) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, Collections.JOBS, docId)
    updateDoc(docRef, {
      ...item,
      updatedAt: new Date(),
    })
      .then(() => {
        console.log('Job Data successfully updated!')
        resolve()
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error updating Job Data: ', error)
        reject()
      })
  })
}

export {
  createJob,
  getJob,
  getJobs,
  getUserJobs,
  deleteJob,
  updateJob,
  getJobsByFilter,
  getJobsV3,
}
