const formatDate = (_time) => {
  if (!_time) return ''
  var date = _time.toDate()
  let year = date.getFullYear() - 2000
  var str =
    (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
    '/' +
    (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
    '/' +
    year
  return str
}

const formatDateTime = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear().toString().slice(-2),
    hour = '' + d.getHours(),
    min = '' + d.getMinutes()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day
  if (hour.length < 2) hour = '0' + hour
  if (min.length < 2) min = '0' + min

  var _date = [month, day, year].join('/')
  return _date + ' ' + hour + ':' + min
}

export { formatDate, formatDateTime }
