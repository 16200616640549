import React from 'react'

const CustomSelect = ({
  onSelect,
  value,
  items,
  label,
  width = 100,
  defaultValue = '',
  disabled = false,
  placeholder = 'Choose option',
}) => {
  return (
    <div
      className={`form-custom-select ${value !== defaultValue ? 'focus-active' : ''}`}
    >
      <label>{label}:</label>
      <select
        className={`custom-select `}
        style={{ width: width + 'px' }}
        value={value}
        disabled={disabled}
        onChange={(e) => {
          onSelect(e.target.value)
        }}
      >
        <option value={''} disabled>
          {placeholder}
        </option>
        {items.map((item) => (
          <option value={item.key} key={item.key}>
            {item.value}
          </option>
        ))}
      </select>
    </div>
  )
}

export default CustomSelect
