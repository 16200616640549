import { withAuthorization } from '../../session'
import { Link, useNavigate } from 'react-router-dom'
import ShowSelect from '../../components/filter/ShowSelect'
import SortSelect from '../../components/filter/SortSelect'
import Pagination from '../../components/filter/Pagination'
import Layout from '../../components/layout/Layout'
import { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getUsers, updateUser } from '../../db/user'
import { getUserJobs } from '../../db/job'
import { formatDate } from '../../modules/functions'
import JobCard from '../../components/elements/JobCard'
import _ from 'lodash'
import JobsGrid from '../Jobs/JobsGrid'
import EmployersTable from './EmployersTable'

function Employers(props) {
  const db = props.firebase.getdb()

  return (
    <>
      <Layout>
        <EmployersTable db={db} />
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Layout>
    </>
  )
}

const condition = (authUser) => !!authUser

export default withAuthorization(condition)(Employers)
