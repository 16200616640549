import React, { useCallback, useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { withFirebase } from '../../firebase'
import Layout from '../../components/layout/Layout'
import { deleteJob, getJob, updateJob } from '../../db/job'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { USA_STATES } from '../../constants/defines'
import DatePicker from 'react-datepicker'
import { DateTime } from 'luxon'
import ConfirmationModal from '../../components/elements/ConfirmationModal'
import Industries from '../../constants/Industries'
import { CITIES_BY_STATE } from '../../constants/citiesByState'

const plus = { days: 30 }
const MINIMAL_DATE = DateTime.utc()
  .minus({ hours: 8 })
  .plus(plus)
  .startOf('day')
  .toJSDate()

const EditJobPage = (props) => {
  let { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [job, setJob] = useState(null)
  const navigate = useNavigate()
  const db = props.firebase.getdb()
  const [showDeleteCaseModal, setShowDeleteCaseModal] = useState(false)

  const toggleDeleteCaseModal = useCallback(() => {
    setShowDeleteCaseModal(!showDeleteCaseModal)
  }, [showDeleteCaseModal])

  useEffect(() => {
    getJob(db, id).then((item) => {
      if (!item?.expiration?.toDate) {
        item.expiration = MINIMAL_DATE
      }

      if (item?.expiration?.toDate) {
        item.expiration = item.expiration.toDate()
      }
      setJob({ ...item })
      setLoading(false)
    })
  }, [])

  const checkApplication = () => {
    const application = job.application
    return !application.includes('http') && !application.includes('https')
  }

  const handleUpdateJob = useCallback(
    (e) => {
      e.preventDefault()
      if (!checkApplication()) {
        toast.error('Please remove http from application URL')
        return
      }

      updateJob(db, id, job).then(() => {
        //setAuthUser(data);
        toast.success('Update successful!')
      })
    },
    [job],
  )

  const handleDeleteJob = useCallback(async () => {
    setLoading(true)
    await deleteJob(db, job)
    navigate('/jobs')
  }, [job, db])

  const handlePublishJob = (e) => {
    e.preventDefault()
    if (!checkApplication()) {
      toast.error('Please remove http from application URL')
      return
    }

    let data = { ...job }
    let msg = ''
    if (job.status === 'published') {
      data.status = 'draft'
      msg = 'Unpublish successful!'
    } else {
      data.status = 'published'
      msg = 'Publish successful!'
    }

    updateJob(db, job.id, data).then(() => {
      toast.success(msg)
      setJob({ ...job, status: data.status })
    })
  }

  const onChange = (event) => {
    let { name, value, checked, type } = event.target
    value = type === 'checkbox' ? checked : value
    setJob({ ...job, [name]: value })

    if (name === 'state') {
      setJob({ ...job, [name]: value, city: '' })
    }
  }

  const onChangeNumber = (e) => {
    const { name, value } = e.target
    if (/^\d*$/.test(value)) {
      setJob({ ...job, [name]: value })
    }
  }

  const renderData = () => {
    return (
      <>
        <div className="section-link">
          <Link className="btn icon-back btn-back" to={'/jobs'}>
            All Jobs
          </Link>
        </div>
        <form className="row form-contact" onSubmit={handleUpdateJob}>
          <div className="col-lg-10 col-md-12">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label className="font-sm color-text-mutted mb-10">
                    Job Title
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    required
                    name="title"
                    value={job.title}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label className="font-sm color-text-mutted mb-10">
                    Company
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    required
                    name="company"
                    value={job.company}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label className="font-sm color-text-mutted mb-10">
                    Add your job description
                  </label>
                  <textarea
                    className="form-control"
                    rows={4}
                    required
                    name="description"
                    value={job.description}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label className="font-sm color-text-mutted mb-10">
                    Application Directions
                  </label>
                  <textarea
                    className="form-control"
                    rows={4}
                    required
                    name="directions"
                    value={job.directions}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="row">
                  <div className="form-group">
                    <label className="font-sm color-text-mutted mb-10">
                      State
                    </label>
                    <select
                      className="form-control mr-10 select-active"
                      required
                      name="state"
                      value={job?.state}
                      onChange={onChange}
                    >
                      <option selected disabled value="">
                        Select a State
                      </option>
                      {USA_STATES.map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-sm color-text-mutted mb-10">
                        City
                      </label>
                      <select
                        className="form-control mr-10 select-active"
                        required
                        disabled={!job.state}
                        name="city"
                        value={job?.city}
                        onChange={onChange}
                        defaultValue=""
                      >
                        <option value="" disabled>
                          Select a City
                        </option>
                        {!!job?.state &&
                          CITIES_BY_STATE[job.state].map((state, index) => (
                            <option value={state} key={`${index}_${state}`}>
                              {state}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-sm color-text-mutted mb-10">
                        Zip code (Optional){' '}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        pattern="\d*"
                        inputMode="numeric"
                        name="zip"
                        onInvalid={(e) =>
                          e.currentTarget.setCustomValidity(
                            'The Zip Code must be 6 digits long.',
                          )
                        }
                        onInput={(e) => e.currentTarget.setCustomValidity('')}
                        value={job.zip}
                        minLength={5}
                        onChange={onChangeNumber}
                        maxLength={5}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="font-sm color-text-mutted mb-10">
                        Application URL (optional)
                      </label>
                      <input
                        className="form-control"
                        type="url"
                        name="application"
                        value={job.application}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="font-sm color-text-mutted mb-10">
                        Industry
                      </label>
                      <select
                        className="form-control mr-10 select-active"
                        required
                        name="industry"
                        value={job.industry}
                        onChange={onChange}
                      >
                        <option selected disabled value="">
                          -
                        </option>
                        {Industries.map((item, index) => (
                          <option
                            value={item.key}
                            key={`edit-job-industry-dropdown-${index}-${item.key}`}
                          >
                            {item.value}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group flex-column d-flex">
                      <label className="font-sm color-text-mutted mb-10">
                        Expiration
                      </label>
                      <DatePicker
                        selected={job.expiration}
                        minDate={new Date()}
                        onChange={(date) => {
                          setJob({ ...job, expiration: date })
                        }}
                        show
                        showFullMonthYearPicker
                        onSelect={(date) => {
                          setJob({ ...job, expiration: date })
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*<div className="col-lg-6 col-md-12">*/}
              {/*  <div className="form-group">*/}
              {/*    <label className="font-sm color-text-mutted mb-10">*/}
              {/*      Contract Type*/}
              {/*    </label>*/}
              {/*    <select*/}
              {/*      className="form-control mr-10 select-active"*/}
              {/*      required*/}
              {/*      name="contract"*/}
              {/*      value={job.contract}*/}
              {/*      onChange={onChange}*/}
              {/*    >*/}
              {/*      {ContractTypes.map((item) => (*/}
              {/*        <option value={item}>{item}</option>*/}
              {/*      ))}*/}
              {/*    </select>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label className="font-sm color-text-mutted mb-10">
                    Salary (Optional)
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="salary"
                    value={job.salary}
                    onChange={onChangeNumber}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex align-content-between justify-content-between">
              <div className="box-button mt-15">
                <button
                  className={`btn btn-publish font-md font-bold mr-20`}
                  type={'submit'}
                >
                  Update
                </button>
                <button
                  className={`btn btn-publish font-md font-bold ${job.status === 'published' && 'gray'}`}
                  onClick={(e) => handlePublishJob(e)}
                >
                  {job.status === 'published' ? 'Unpublish' : 'Publish'}
                </button>
              </div>
              <div className="box-button">
                <button
                  className={`btn btn-danger font-md font-bold ml-20`}
                  onClick={toggleDeleteCaseModal}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </form>
        <ConfirmationModal
          modalToggle={showDeleteCaseModal}
          onClose={toggleDeleteCaseModal}
          title={'Delete Case'}
          buttons={() => {
            return (
              <>
                <button
                  className={`btn btn-publish font-md font-bold mr-20`}
                  onClick={toggleDeleteCaseModal}
                >
                  Cancel
                </button>
                <button
                  className={`btn btn-danger font-md font-bold`}
                  onClick={handleDeleteJob}
                >
                  Delete
                </button>
              </>
            )
          }}
          description={'Would you like to delete this case'}
        />
      </>
    )
  }

  return (
    <Layout>
      <section className="section-box text-start">
        <div className="container">
          {loading && <span>Loading data...</span>}
          {!loading && renderData()}
        </div>
      </section>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Layout>
  )
}

export default withFirebase(EditJobPage)
