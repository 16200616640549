import { withAuthorization } from '../../session'
import Layout from '../../components/layout/Layout'
import { useCallback, useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getUsers } from '../../db/user'
import JobsGrid from './JobsGrid'

function Jobs(props) {
  const [textSearch, setTextSearch] = useState('')
  const db = props.firebase.getdb()
  const onSearch = (event) => {
    if (event.key === 'Enter') {
      setTextSearch(event.target.value)
    }
  }

  return (
    <>
      <Layout>
        <JobsGrid db={db} />
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Layout>
    </>
  )
}

const condition = (authUser) => !!authUser

export default withAuthorization(condition)(Jobs)
