import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { withAuthorization } from '../../session'
import Layout from '../../components/layout/Layout'
import { createJob } from '../../db/job'
import { USA_STATES } from '../../constants/defines'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import DatePicker from 'react-datepicker'
import { DateTime } from 'luxon'
import { CITIES_BY_STATE } from '../../constants/citiesByState'
import Industries from '../../constants/Industries'

const plus = { days: 30 }
const MINIMAL_DATE = DateTime.utc()
  .minus({ hours: 8 })
  .plus(plus)
  .startOf('day')
  .toJSDate()

const INITIAL_STATE = {
  title: '',
  description: '',
  personal: '',
  country: '',
  state: '',
  city: '',
  zip: '',
  contract: '',
  salary: '',
  directions: '',
  application: '',
  level: '',
  expiration: MINIMAL_DATE,
  userId: '',
  status: 'draft',
}

function PostaJob(props) {
  const [job, setJob] = useState({ ...INITIAL_STATE })

  const user = props.user
  const db = props.firebase.getdb()

  const checkApplication = () => {
    const application = job.application
    return !application.includes('http') && !application.includes('https')
  }

  const onSubmit = (e) => {
    e.preventDefault()

    if (!checkApplication()) {
      toast.error('Please remove http from application URL')
      return
    }

    console.log(job.expiration)

    // return
    createJob(db, user.uid, { ...job, status: 'published' }).then((jobId) => {
      console.log('Job Created. ', jobId)
      toast.success('Create successful!')
      window.location = '/jobs'
    })
  }

  const onChange = (event) => {
    let { name, value, checked, type } = event.target
    value = type === 'checkbox' ? checked : value
    setJob({ ...job, [name]: value })

    if (name === 'state') {
      setJob({ ...job, [name]: value, city: '' })
    }
  }

  const onChangeNumber = (e) => {
    const { name, value } = e.target
    if (/^\d*$/.test(value)) {
      setJob({ ...job, [name]: value })
    }
  }

  const renderHeader = () => {
    return (
      <div className="section-header">
        <Link to="/jobs" className="btn icon-back btn-back">
          All Jobs
        </Link>
      </div>
    )
  }

  const renderForm = () => {
    return (
      <form className="row form-contact" onSubmit={onSubmit}>
        <div className="col-lg-10 col-md-12">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="form-group">
                <label className="font-sm color-text-mutted mb-10">
                  Job Title
                </label>
                <input
                  className="form-control"
                  type="text"
                  required
                  name="title"
                  value={job.title}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="form-group">
                <label className="font-sm color-text-mutted mb-10">
                  Company
                </label>
                <input
                  className="form-control"
                  type="text"
                  required
                  name="company"
                  value={job.company}
                  onChange={onChange}
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="form-group">
                <label className="font-sm color-text-mutted mb-10">
                  Add your job description <span>*</span>
                </label>
                <textarea
                  className="form-control"
                  rows={4}
                  required
                  name="description"
                  value={job.description}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="form-group">
                <label className="font-sm color-text-mutted mb-10">
                  Application Directions
                </label>
                <textarea
                  className="form-control"
                  rows={4}
                  required
                  name="directions"
                  value={job.directions}
                  onChange={onChange}
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="row">
                <div className="form-group">
                  <label className="font-sm color-text-mutted mb-10">
                    State
                  </label>
                  <select
                    className="form-control mr-10 select-active"
                    required
                    name="state"
                    value={job?.state}
                    onChange={onChange}
                    defaultValue={''}
                  >
                    <option value={''} disabled>
                      Select a State
                    </option>
                    {USA_STATES.map((item) => (
                      <option value={item}>{item}</option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="font-sm color-text-mutted mb-10">
                      City
                    </label>
                    <select
                      className="form-control mr-10 select-active"
                      required
                      disabled={!job.state}
                      name="city"
                      value={job?.city}
                      onChange={onChange}
                      defaultValue=""
                    >
                      <option value="" disabled>
                        Select a City
                      </option>
                      {!!job?.state &&
                        CITIES_BY_STATE[job.state].map((state, index) => (
                          <option value={state} key={`${index}_${state}`}>
                            {state}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="font-sm color-text-mutted mb-10">
                      Zip code (Optional){' '}
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      pattern="\d*"
                      inputMode="numeric"
                      name="zip"
                      onInvalid={(e) =>
                        e.currentTarget.setCustomValidity(
                          'The Zip Code must be 6 digits long.',
                        )
                      }
                      onInput={(e) => e.currentTarget.setCustomValidity('')}
                      value={job.zip}
                      minLength={6}
                      onChange={onChangeNumber}
                      maxLength={6}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="font-sm color-text-mutted mb-10">
                      Application URL (optional)
                    </label>
                    <input
                      className="form-control"
                      type="url"
                      name="application"
                      value={job.application}
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="font-sm color-text-mutted mb-10">
                      Industry
                    </label>
                    <select
                      className="form-control mr-10 select-active"
                      required
                      name="industry"
                      value={job.industry}
                      onChange={onChange}
                    >
                      <option value={''}>-</option>
                      {Industries.map((item, index) => (
                        <option
                          value={item.key}
                          key={`edit-job-industry-dropdown-${index}-${item.key}`}
                        >
                          {item.value}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group flex-column d-flex">
                    <label className="font-sm color-text-mutted mb-10">
                      Expiration
                    </label>
                    <DatePicker
                      selected={job.expiration}
                      minDate={new Date()}
                      onChange={(date) => {
                        setJob({
                          ...job,
                          expiration: date,
                        })
                      }}
                      show
                      showFullMonthYearPicker
                      onSelect={(date) => {
                        setJob({
                          ...job,
                          expiration: date,
                        })
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/*<div className="col-lg-6 col-md-12">*/}
            {/*  <div className="form-group">*/}
            {/*    <label className="font-sm color-text-mutted mb-10">*/}
            {/*      Contract Type*/}
            {/*    </label>*/}
            {/*    <select*/}
            {/*      className="form-control mr-10 select-active"*/}
            {/*      required*/}
            {/*      name="contract"*/}
            {/*      value={job.contract}*/}
            {/*      onChange={onChange}*/}
            {/*    >*/}
            {/*      {ContractTypes.map((item) => (*/}
            {/*        <option value={item}>{item}</option>*/}
            {/*      ))}*/}
            {/*    </select>*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div className="col-lg-6 col-md-12">
              <div className="form-group">
                <label className="font-sm color-text-mutted mb-10">
                  Salary (Optional)
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="salary"
                  value={job.salary}
                  onChange={onChangeNumber}
                />
              </div>
            </div>
          </div>

          <div className="section-action mt-15">
            <button
              className={`btn btn-publish font-md font-bold`}
              type="submit"
            >
              Create
            </button>
          </div>
        </div>
      </form>
    )
  }

  return (
    <>
      <Layout>
        <div className="row">
          <div className="col-lg-12">
            <div className="section-box">
              <div className="container">
                {renderHeader()}

                {renderForm()}
              </div>
            </div>
          </div>
        </div>

        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Layout>
    </>
  )
}

const condition = (authUser) => !!authUser

export default withAuthorization(condition)(PostaJob)
