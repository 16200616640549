import { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { withFirebase } from '../firebase'
import { updateUserignedIn } from '../db/user'

const INITIAL_STATE = {
  email: '',
  password: '',
}

const Signin = (props) => {
  const [user, setUser] = useState({ ...INITIAL_STATE })

  const db = props.firebase.getdb()

  const onSubmit = (e) => {
    e.preventDefault()
    props.firebase
      .doSignInWithEmailAndPassword(user.email, user.password)
      .then((userCredential) => {
        updateUserignedIn(db, userCredential.user.uid).then(() => {
          localStorage.setItem('currentuser', user.email)
          window.location.href = `/jobs`
        })
      })
      .catch((error) => {
        toast.error(error)
      })
  }

  const onChange = (event) => {
    let { name, value, checked, type } = event.target
    value = type === 'checkbox' ? checked : value

    setUser({ ...user, [name]: value })
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="section-box">
            <div className="container">
              <div className="panel-white mb-30">
                <div className="box-padding">
                  <div className="login-register">
                    <div className="row login-register-cover pb-250">
                      <div className="col-lg-4 col-md-6 col-sm-12 mx-auto">
                        <div className="form-login-cover">
                          <div className="text-center">
                            <h2 className="mt-10 mb-5 text-brand-1 mb-30">
                              Admin Login
                            </h2>
                          </div>
                          <form
                            className="login-register text-start mt-20"
                            onSubmit={onSubmit}
                          >
                            <div className="form-group">
                              <label className="form-label" htmlFor="input-1">
                                Username or Email address *
                              </label>
                              <input
                                className="form-control"
                                id="input-1"
                                type="text"
                                required=""
                                name="email"
                                placeholder="Email"
                                value={user.email}
                                onChange={onChange}
                              />
                            </div>
                            <div className="form-group">
                              <label className="form-label" htmlFor="input-4">
                                Password *
                              </label>
                              <input
                                className="form-control"
                                id="input-4"
                                type="password"
                                required=""
                                name="password"
                                placeholder="************"
                                value={user.password}
                                onChange={onChange}
                              />
                            </div>
                            <div className="login_footer form-group d-flex justify-content-between">
                              <a className="text-muted" href="/reset-password">
                                Forgot Password
                              </a>
                            </div>
                            <div className="form-group">
                              <button
                                className="btn btn-brand-1 hover-up w-100"
                                type="submit"
                                name="login"
                              >
                                Login
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  )
}

export default withFirebase(Signin)
