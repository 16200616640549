import { withAuthorization } from '../../session'
import { useEffect, useState } from 'react'
import { createUser } from '../../db/user'
import _ from 'lodash'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Layout from '../../components/layout/Layout'
import { IndustryTypes } from '../../constants/defines'

const INIT_DATA = {
  isAdmin: false,
  name: '',
  email: '',
  phone: '',
  company: '',
  industry: '',
  web: '',
  country: '',
  state: '',
  city: '',
  zip: '',
}

const CreateProfile = (props) => {
  const [data, setData] = useState({ ...INIT_DATA })
  const [password, setPassword] = useState('')
  const [confirmpassword, setConfirmPassword] = useState('')

  const db = props.firebase.getdb()

  const onSubmitProfile = (e) => {
    e.preventDefault()

    if (password === '') {
      if (password !== confirmpassword) {
        toast.error('Empty Password')
        return
      }
    }
    if (password !== confirmpassword) {
      toast.error('Passwords do NOT match!')
      return
    }

    props.firebase
      .doCreateUserWithEmailAndPassword(data.email, password)
      .then((authUser) => {
        createUser(db, authUser.user.uid, data).then((userid) => {
          console.log(userid)
          toast.success('Register successful!')
          window.location.href = `/admins`
        })
      })
      .catch((error) => {
        console.log(error)
        toast.error(error)
      })
  }

  const onChange = (event) => {
    let { name, value, checked, type } = event.target
    value = type === 'checkbox' ? checked : value

    setData({ ...data, [name]: value })
  }

  const onChangePassword = (event) => {
    let { name, value, checked, type } = event.target
    value = type === 'checkbox' ? checked : value

    if (name === 'password') setPassword(value)
    else setConfirmPassword(value)
  }

  return (
    <>
      <Layout>
        <div className="col-lg-12">
          <div className="section-box">
            <div className="container">
              <div className="panel-white mb-30">
                <div className="box-padding">
                  <div className="row form-contact">
                    <form onSubmit={onSubmitProfile}>
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <label className="font-sm color-text-mutted mb-10">
                            Full Name *
                          </label>
                          <input
                            className="form-control"
                            required
                            type="text"
                            name="name"
                            value={data.name}
                            onChange={onChange}
                          />
                        </div>
                        <div className="form-group">
                          <label className="font-sm color-text-mutted mb-10">
                            Email *
                          </label>
                          <input
                            className="form-control"
                            required
                            type="text"
                            name="email"
                            value={data.email}
                            onChange={onChange}
                          />
                        </div>
                        <div className="form-group">
                          <label className="font-sm color-text-mutted mb-10">
                            Contact number
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="phone"
                            value={data.phone}
                            onChange={onChange}
                          />
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="font-sm color-text-mutted mb-10">
                                Company
                              </label>
                              <input
                                className="form-control"
                                required
                                type="text"
                                name="company"
                                value={data.company}
                                onChange={onChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="font-sm color-text-mutted mb-10">
                                Industry
                              </label>

                              <select
                                className="form-control select-active"
                                required
                                name="industry"
                                value={data.industry}
                                onChange={onChange}
                              >
                                <option value={''}>-</option>
                                {IndustryTypes.map((item) => (
                                  <option value={item}>{item}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="font-sm color-text-mutted mb-10">
                            Company website
                          </label>
                          <input
                            className="form-control"
                            type="url"
                            name="web"
                            value={data.web}
                            onChange={onChange}
                          />
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="font-sm color-text-mutted mb-10">
                                Country
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="country"
                                value={data.country}
                                onChange={onChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="font-sm color-text-mutted mb-10">
                                State
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="state"
                                value={data.state}
                                onChange={onChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="font-sm color-text-mutted mb-10">
                                City
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="city"
                                value={data.city}
                                onChange={onChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="font-sm color-text-mutted mb-10">
                                Zip code
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="zip"
                                value={data.zip}
                                onChange={onChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="border-bottom pt-10 pb-10 mb-30" />
                        <h6 className="color-orange mb-20">
                          Change your password
                        </h6>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-label" htmlFor="input-4">
                                Password *
                              </label>
                              <input
                                className="form-control"
                                id="input-4"
                                type="password"
                                required
                                name="password"
                                placeholder="************"
                                value={password}
                                onChange={onChangePassword}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-label" htmlFor="input-5">
                                Re-Password *
                              </label>
                              <input
                                className="form-control"
                                id="input-5"
                                type="password"
                                required
                                name="confirmpassword"
                                placeholder="************"
                                value={confirmpassword}
                                onChange={onChangePassword}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="border-bottom pt-10 pb-10" />
                        <div className="box-button mt-15">
                          <button className="btn btn-apply-form font-md font-bold">
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </form>

                    <ToastContainer
                      position="bottom-center"
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="light"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

const condition = (authUser) => !!authUser

export default withAuthorization(condition)(CreateProfile)
