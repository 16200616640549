export default function Pagination({
  prev,
  currentPage,
  getPaginationGroup,
  next,
  pages,
  handleActive,
}) {
  const handleClickPrev = (e) => {
    e.preventDefault()
    if (currentPage > pages && prev) prev()
  }

  const handleClickNext = (e) => {
    e.preventDefault()
    if (currentPage < pages && next) next()
  }

  return (
    <>
      {pages > 1 && (
        <ul className="pager">
          <li onClick={(e) => handleClickPrev(e)}>
            <a className="pager-prev" />
          </li>
          {getPaginationGroup.map((item, index) => {
            return (
              <li onClick={() => handleActive(item)} key={index}>
                <a
                  className={
                    currentPage === item ? 'page-number active' : 'page-number'
                  }
                >
                  {item}
                </a>
              </li>
            )
          })}

          <li onClick={(e) => handleClickNext(e)}>
            <a className="pager-next" />
          </li>
        </ul>
      )}
    </>
  )
}
