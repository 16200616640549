import { Link } from 'react-router-dom'
import React from 'react'

export default function ConfirmationModal({
  modalToggle,
  handleModal,
  onClose,
  title,
  buttons,
  description,
}) {
  return (
    <>
      <div
        className={`modal fade ${modalToggle ? 'show' : ''}`}
        style={modalToggle ? { paddingRight: 17, display: 'block' } : null}
      >
        <div className="modal-dialog modal-lg" style={{ zIndex: '9999' }}>
          <div className="modal-content">
            <div className="modal-body pl-15 pr-15 pt-15  ">
              <div>
                <h4 className=" mb-5  text-capitalize">{title}</h4>
                <button className="btn-close" type="button" onClick={onClose} />
              </div>
              <p>{description}</p>
              <div className="d-flex flex-row-reverse">
                <div className="box-button mt-15 flex-row-reverse">
                  {buttons()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show" onClick={handleModal} />
      </div>
    </>
  )
}
