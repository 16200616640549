const CareerjetCities = [
  // TEXAS
  'Houston,-TX',
  'Dallas,-TX',
  'Austin,-TX',
  'San-Antonio,-TX',
  'Fort-Worth,-TX',
  'Irving,-TX',
  'Arlington,-TX',
  'Corpus-Christi,-TX',
  'Lubbock,-TX',
  'Waco,-TX',
  'Grand-Prairie,-TX',
  'Abilene,-TX',
  'Beaumont,-TX',
  'Pasadena,-TX',
  'Garland,-TX',
  'Carrollton,-TX',
  'Mesquite,-TX',
  'Laredo,-TX',
  'McAllen,-TX',
  'Brownsville,-TX',
  'Killeen,-TX',
  'Wichita-Falls,-TX',
  // CALIFORNIA
  'Los-Angeles,-CA',
  'San-Francisco,-CA',
  'San-Diego,-CA',
  'San-Jose,-CA',
  'Sacramento,-CA',
  'Irvine,-CA',
  'Oakland,-CA',
  'Fresno,-CA',
  'Santa-Clara,-CA',
  'Sunnyvale,-CA',
  'Long-Beach,-CA',
  'Anaheim,-CA',
  'Bakersfield,-CA',
  'Torrance,-CA',
  'Palo-Alto,-CA',
  'Santa-Ana,-CA',
  'Stockton,-CA',
  'Santa-Rosa,-CA',
  'Costa-Mesa,-CA',
  'Burbank,-CA',
  'Thousand-Oaks,-CA',
  'Glendale,-CA',
  'Pasadena,-CA',
  'San-Bernardino,-CA',
  'Berkeley,-CA',
  'Hayward,-CA',
  'Oceanside,-CA',
  'Escondido,-CA',
  'Chula-Vista,-CA',
  'Fullerton,-CA',
  'Huntington-Beach,-CA',
  'Fairfield,-CA',
  'Inglewood,-CA',
  'Oxnard,-CA',
  'Rancho-Cucamonga,-CA',
  'Salinas,-CA',
  'Palmdale,-CA',
  'Pomona,-CA',
  'Downey,-CA',
  'Santa-Clarita,-CA',
  'Vallejo,-CA',
  'Simi-Valley,-CA',
  'Moreno-Valley,-CA',
  'Richmond,-CA',
  'Garden-Grove,-CA',
  'Lancaster,-CA',
  'Daly-City,-CA',
  'West-Covina,-CA',
  'Norwalk,-CA',
  // FLORIDA
  'Orlando,-FL',
  'Tampa,-FL',
  'Jacksonville,-FL',
  'Miami,-FL',
  'Fort-Lauderdale,-FL',
  'Fort-Myers,-FL',
  'Tallahassee,-FL',
  'Sarasota,-FL',
  'Saint-Petersburg,-FL',
  'Pensacola,-FL',
  'West-Palm-Beach,-FL',
  'Boca-Raton,-FL',
  'Clearwater,-FL',
  'Kissimmee,-FL',
  'Delray-Beach,-FL',
  'Hialeah,-FL',
  // NEW YORK
  'New-York-City,-NY',
  'Rochester,-NY',
  'Buffalo,-NY',
  'Albany,-NY',
  'Syracuse,-NY',
  'White-Plains,-NY',
  'Utica,-NY',
  'Stony-Brook,-NY',
  'Ithaca,-NY',
  'Yonkers,-NY',
  'Saratoga-Springs,-NY',
  'Schenectady,-NY',
  // ILLINOIS
  'Chicago,-IL',
  'Springfield,-IL',
  'Naperville,-IL',
  'Peoria,-IL',
  'Rockford,-IL',
  'Aurora,-IL',
  'Schaumburg,-IL',
  'Elgin,-IL',
  'Joliet,-IL',
  'Arlington-Heights,-IL',
  'Waukegan,-IL',
  'Hoffman-Estates,-IL',
  // PENNSYLVANIA
  'Philadelphia,-PA',
  'Pittsburgh,-PA',
  'Harrisburg,-PA',
  'Lancaster,-PA',
  'Allentown,-PA',
  'York,-PA',
  'Erie,-PA',
  'Reading,-PA',
  'King-of-Prussia,-PA',
  'Bethlehem,-PA',
  'Wilkes-Barre,-PA',
  'Mechanicsburg,-PA',
  // VIRGINIA
  'Richmond,-VA',
  'Arlington,-VA',
  'Virginia-Beach,-VA',
  'Norfolk,-VA',
  'Charlottesville,-VA',
  'Roanoke,-VA',
  'McLean,-VA',
  'Alexandria,-VA',
  'Chesapeake,-VA',
  'Newport-News,-VA',
  'Manassas,-VA',
  'Hampton,-VA',
  // NORTH CAROLINA
  'Charlotte,-NC',
  'Raleigh,-NC',
  'Durham,-NC',
  'Winston-Salem,-NC',
  'Greensboro,-NC',
  'Fayetteville,-NC',
  'Wilmington,-NC',
  'Asheville,-NC',
  'Cary,-NC',
  'Chapel-Hill,-NC',
  'Concord,-NC',
  'High-Point,-NC',
  // OHIO
  'Columbus,-OH',
  'Cincinnati,-OH',
  'Cleveland,-OH',
  'Dayton,-OH',
  'Akron,-OH',
  'Toledo,-OH',
  'Canton,-OH',
  'Dublin,-OH',
  'West-Chester,-OH',
  'Westerville,-OH',
  'Youngstown,-OH',
  'Mason,-OH',
  // GEORGIA
  'Atlanta,-GA',
  'Savannah,-GA',
  'Marietta,-GA',
  'Alpharetta,-GA',
  'Augusta,-GA',
  'Macon,-GA',
  'Columbus,-GA',
  'Lawrenceville,-GA',
  'Athens,-GA',
  'Cumming,-GA',
  'Gainesville,-GA',
  'Norcross,-GA',
  // MASSACHUSETTS
  'Boston,-MA',
  'Cambridge,-MA',
  'Worcester,-MA',
  'Springfield,-MA',
  'Waltham,-MA',
  'Andover,-MA',
  'Brockton,-MA',
  'New-Bedford,-MA',
  'Framingham,-MA',
  'Burlington,-MA',
  'Newton,-MA',
  'Lowell,-MA',
  // TENNESSEE
  'Nashville,-TN',
  'Memphis,-TN',
  'Knoxville,-TN',
  'Chattanooga,-TN',
  'Murfreesboro,-TN',
  'Franklin,-TN',
  'Brentwood,-TN',
  'Clarksville,-TN',
  'Lebanon,-TN',
  'Johnson-City,-TN',
  'Smyrna,-TN',
  'Hendersonville,-TN',
  // MICHIGAN
  'Detroit,-MI',
  'Grand-Rapids,-MI',
  'Lansing,-MI',
  'Ann-Arbor,-MI',
  'Troy,-MI',
  'Kalamazoo,-MI',
  'Southfield,-MI',
  'Livonia,-MI',
  'Dearborn,-MI',
  'Novi,-MI',
  'Warren,-MI',
  'Sterling-Heights,-MI',
  // NEW JERSEY
  'Newark,-NJ',
  'Jersey-City,-NJ',
  'Princeton,-NJ',
  'Camden,-NJ',
  'Atlantic-City,-NJ',
  'Trenton,-NJ',
  'Vineland,-NJ',
  'New-Brunswick,-NJ',
  'Hackensack,-NJ',
  'Paramus,-NJ',
  'Parsippany,-NJ',

  'Paterson,-NJ',
  // MARYLAND
  'Baltimore,-MD',
  'Glen-Burnie,-MD',
  'Bethesda,-MD',
  'Annapolis-Junction,-MD',
  'Rockville,-MD',
  'Silver-Spring,-MD',
  'Columbia,-MD',
  'Frederick,-MD',
  'Annapolis,-MD',
  'Gaithersburg,-MD',
  'Laurel,-MD',
  'Randallstown,-MD',
  // Connecticut
  'Hartford,-CT',
  'New-Haven,-CT',
  'Waterbury,-CT',
  'Stamford,-CT',
  'Bridgeport,-CT',
  'Norwalk,-CT',
  'West-Hartford,-CT',
  'Manchester,-CT',
  'Danbury,-CT',
  'New-Britain,-CT',
  'Greenwich,-CT',
  'Shelton,-CT',
  // ARIZONA
  'Phoenix,-AZ',
  'Tucson,-AZ',
  'Mesa,-AZ',
  'Scottsdale,-AZ',
  'Tempe,-AZ',
  'Glendale,-AZ',
  'Chandler,-AZ',
  'Gilbert,-AZ',
  'Peoria,-AZ',
  'Flagstaff,-AZ',
  'Yuma,-AZ',
  'Sierra-Vista,-AZ',
  'Kingman,-AZ',
  // COLORADO
  'Denver,-CO',
  'Colorado-Springs,-CO',
  'Aurora,-CO',
  'Littleton,-CO',
  'Fort-Collins,-CO',
  'Lakewood,-CO',
  'Boulder,-CO',
  'Englewood,-CO',
  'Arvada,-CO',
  'Greeley,-CO',
  'Pueblo,-CO',
  'Westminster,-CO',
  'Grand-Junction,-CO',
  'Broomfield,-CO',
  'Golden,-CO',
  'Greenwood-Village,-CO',
  // INDIANA
  'Indianapolis,-IN',
  'Fort-Wayne,-IN',
  'Evansville,-IN',
  'South-Bend,-IN',
  'Lafayette,-IN',
  'Bloomington,-IN',
  'Carmel,-IN',
  'Terre-Haute,-IN',
  'Fishers,-IN',
  'Greenwood,-IN',
  'Noblesville,-IN',
  'Elkhart,-IN',
  // WASHINGTON
  'Seattle,-WA',
  'Spokane,-WA',
  'Tacoma,-WA',
  'Bellevue,-WA',
  'Vancouver,-WA',
  'Everett,-WA',
  'Redmond,-WA',
  'Kent,-WA',
  'Olympia,-WA',
  'Yakima,-WA',
  'Issaquah,-WA',
  'Bremerton,-WA',
  // WISCONSIN
  'Milwaukee,-WI',
  'Madison,-WI',
  'Green-Bay,-WI',
  'Appleton,-WI',
  'Waukesha,-WI',
  'Racine,-WI',
  'Kenosha,-WI',
  'Brookfield,-WI',
  'Oshkosh,-WI',
  'Eau-Claire,-WI',
  'Menomonee-Falls,-WI',
  'La-Crosse,-WI',
  // MINNESOTA
  'Minneapolis,-MN',
  'Saint-Paul,-MN',
  'Duluth,-MN',
  'Bloomington,-MN',
  'Rochester,-MN',
  'Saint-Cloud,-MN',
  'Eden-Prairie,-MN',
  'Plymouth,-MN',
  'Maple-Grove,-MN',
  'Eagan,-MN',
  'Minnetonka,-MN',
  'Mankato,-MN',
  // MISSOURI
  'Saint-Louis,-MO',
  'Kansas-City,-MO',
  'Springfield,-MO',
  'Columbia,-MO',
  'Chesterfield,-MO',
  'Saint-Charles,-MO',
  'Saint-Peters,-MO',
  'Jefferson-City,-MO',
  'Independence,-MO',
  'Joplin,-MO',
  "Lee's-Summit,-MO",
  'Cape-Girardeau,-MO',
  // SOUTH CAROLINA
  'South-Carolina',
  'Columbia,-SC',
  'Charleston,-SC',
  'Greenville,-SC',
  'Myrtle-Beach,-SC',
  'North-Charleston,-SC',
  'Spartanburg,-SC',
  'Mount-Pleasant,-SC',
  'Rock-Hill,-SC',
  'Summerville,-SC',
  'Florence,-SC',
  'West-Columbia,-SC',
  'Aiken,-SC',
  // IOWA
  'Des-Moines,-IA',
  'Cedar-Rapids,-IA',
  'Davenport,-IA',
  'Sioux-City,-IA',
  'Dubuque,-IA',
  'West-Des-Moines,-IA',
  'Council-Bluffs,-IA',
  'Waterloo,-IA',
  'Iowa-City,-IA',
  'Mason-City,-IA',
  'Ames,-IA',
  'Urbandale,-IA',
  'Cedar-Falls,-IA',
  'Fort-Dodge,-IA',
  // ALABAMA
  'Birmingham,-AL',
  'Huntsville,-AL',
  'Montgomery,-AL',
  'Mobile,-AL',
  'Tuscaloosa,-AL',
  'Decatur,-AL',
  'Auburn,-AL',
  'Dothan,-AL',
  'Hoover,-AL',
  'Anniston,-AL',
  'Gadsden,-AL',
  'Selma,-AL',
  // OREGON
  'Portland,-OR',
  'Salem,-OR',
  'Eugene,-OR',
  'Beaverton,-OR',
  'Hillsboro,-OR',
  'Medford,-OR',
  'Tualatin,-OR',
  'Gresham,-OR',
  'Corvallis,-OR',
  'Tigard,-OR',
  'Springfield,-OR',
  'Albany,-OR',
  // KENTUCKY
  'Louisville,-KY',
  'Lexington,-KY',
  'Bowling-Green,-KY',
  'Florence,-KY',
  'Elizabethtown,-KY',
  'Owensboro,-KY',
  'Erlanger,-KY',
  'Paducah,-KY',
  'Frankfort,-KY',
  'Georgetown,-KY',
  'Hopkinsville,-KY',
  'Somerset,-KY',
  // LOUISIANA
  'New-Orleans,-LA',
  'Baton-Rouge,-LA',
  'Shreveport,-LA',
  'Lafayette,-LA',
  'Lake-Charles,-LA',
  'Metairie,-LA',
  'Alexandria,-LA',
  'Monroe,-LA',
  'Covington,-LA',
  'Kenner,-LA',
  'Bossier-City,-LA',
  'Houma,-LA',
  // OKLAHOMA
  'Oklahoma-City,-OK',
  'Tulsa,-OK',
  'Norman,-OK',
  'Edmond,-OK',
  'Broken-Arrow,-OK',
  'Lawton,-OK',
  'Stillwater,-OK',
  'Ardmore,-OK',
  'Muskogee,-OK',
  'Jenks,-OK',
  'Enid,-OK',
  'Yukon,-OK',
  // KANSAS
  'Wichita,-KS',
  'Overland-Park,-KS',
  'Kansas-City,-KS',
  'Topeka,-KS',
  'Olathe,-KS',
  'Lenexa,-KS',
  'Lawrence,-KS',
  'Shawnee-Mission,-KS',
  'Salina,-KS',
  'Manhattan,-KS',
  'Shawnee,-KS',
  'Hutchinson,-KS',
  // NEBRASKA
  'Omaha,-NE',
  'Lincoln,-NE',
  'Grand-Island,-NE',
  'Kearney,-NE',
  'North-Platte,-NE',
  'Fremont,-NE',
  'Norfolk,-NE',
  'Papillion,-NE',
  'Bellevue,-NE',
  'Columbus,-NE',
  'Gretna,-NE',
  'Scottsbluff,-NE',
  // DISTRICT OF COLUMBIA
  'Washington-DC',
  'Joint-Base-Anacostia-Bolling,-DC',
  // UTAH
  'Salt-Lake-City,-UT',
  'Ogden,-UT',
  'Provo,-UT',
  'Saint-George,-UT',
  'Lehi,-UT',
  'Sandy,-UT',
  'Orem,-UT',
  'West-Valley-City,-UT',
  'South-Jordan,-UT',
  'Draper,-UT',
  'Murray,-UT',
  'Park-City,-UT',
  // NEVADA
  'Las-Vegas,-NV',
  'Reno,-NV',
  'Henderson,-NV',
  'North-Las-Vegas,-NV',
  'Sparks,-NV',
  'Carson-City,-NV',
  'Elko,-NV',
  'Laughlin,-NV',
  'Stateline,-NV',
  'Fallon,-NV',
  'Fernley,-NV',
  'Winnemucca,-NV',
  // ARKANSAS
  'Little-Rock,-AR',
  'Bentonville,-AR',
  'Fort-Smith,-AR',
  'Fayetteville,-AR',
  'Springdale,-AR',
  'Rogers,-AR',
  'Conway,-AR',
  'Jonesboro,-AR',
  'North-Little-Rock,-AR',
  'Hot-Springs,-AR',
  'Benton,-AR',
  'Pine-Bluff,-AR',
  // NEW MEXICO
  'Albuquerque,-NM',
  'Santa-Fe,-NM',
  'Las-Cruces,-NM',
  'Rio-Rancho,-NM',
  'Alamogordo,-NM',
  'Carlsbad,-NM',
  'Farmington,-NM',
  'Hobbs,-NM',
  'Gallup,-NM',
  'Roswell,-NM',
  'Clovis,-NM',
  'Los-Alamos,-NM',
  // NEW HAMPSHIRE
  'Manchester,-NH',
  'Nashua,-NH',
  'Concord,-NH',
  'Portsmouth,-NH',
  'Keene,-NH',
  'Lebanon,-NH',
  'Dover,-NH',
  'Salem,-NH',
  'Merrimack,-NH',
  'Bedford,-NH',
  'Laconia,-NH',
  'Rochester,-NH',
  // MISSISSIPPI
  'Jackson,-MS',
  'Hattiesburg,-MS',
  'Gulfport,-MS',
  'Biloxi,-MS',
  'Olive-Branch,-MS',
  'Tupelo,-MS',
  'Meridian,-MS',
  'Southaven,-MS',
  'Flowood,-MS',
  'Columbus,-MS',
  'Pascagoula,-MS',
  'Ridgeland,-MS',
  // SOUTH DAKOTA
  'South-Dakota',
  'Sioux-Falls,-SD',
  'Rapid-City,-SD',
  'Brookings,-SD',
  'Aberdeen,-SD',
  'Yankton,-SD',
  'Brandon,-SD',
  'Pierre,-SD',
  'Watertown,-SD',
  'Canton,-SD',
  'Dell-Rapids,-SD',
  'Harrisburg,-SD',
  'Tea,-SD',
  // MAINE
  'Portland,-ME',
  'Bangor,-ME',
  'Lewiston,-ME',
  'Augusta,-ME',
  'Scarborough,-ME',
  'South-Portland,-ME',
  'Westbrook,-ME',
  'Auburn,-ME',
  'Biddeford,-ME',
  'Brunswick,-ME',
  'Saco,-ME',
  'Waterville,-ME',
  // IDAHO
  'Boise,-ID',
  'Idaho-Falls,-ID',
  'Meridian,-ID',
  'Nampa,-ID',
  'Twin-Falls,-ID',
  "Coeur-d'Alene,-ID",
  'Pocatello,-ID',
  'Lewiston,-ID',
  'Caldwell,-ID',
  'Post-Falls,-ID',
  'Moscow,-ID',
  'Burley,-ID',
  // HAWAII
  'Honolulu,-HI',
  'Hilo,-HI',
  'Oahu,-HI',
  'Kahului,-HI',
  'Kailua-Kona,-HI',
  'Lihue,-HI',
  'Kapolei,-HI',
  'Maui,-HI',
  'Lahaina,-HI',
  'Kailua,-HI',
  'Waipahu,-HI',
  'Aiea,-HI',
  // MONTANA
  'Billings,-MT',
  'Missoula,-MT',
  'Bozeman,-MT',
  'Great-Falls,-MT',
  'Helena,-MT',
  'Kalispell,-MT',
  'Butte,-MT',
  'Belgrade,-MT',
  'Big-Sky,-MT',
  'Miles-City,-MT',
  'Havre,-MT',
  'Glendive,-MT',
  // WEST VIRGINIA
  'Charleston,-WV',
  'Morgantown,-WV',
  'Huntington,-WV',
  'Beckley,-WV',
  'Wheeling,-WV',
  'Martinsburg,-WV',
  'Parkersburg,-WV',
  'Clarksburg,-WV',
  'Bridgeport,-WV',
  'Fairmont,-WV',
  'South-Charleston,-WV',
  'Weirton,-WV',
  // NORTH DAKOTA
  'Fargo,-ND',
  'Bismarck,-ND',
  'Grand-Forks,-ND',
  'Minot,-ND',
  'Williston,-ND',
  'Dickinson,-ND',
  'West-Fargo,-ND',
  'Jamestown,-ND',
  'Mandan,-ND',
  'Watford-City,-ND',
  'Devils-Lake,-ND',
  'Wahpeton,-ND',
  // DELAWARE
  'Wilmington,-DE',
  'Newark,-DE',
  'Dover,-DE',
  'Milford,-DE',
  'New-Castle,-DE',
  'Seaford,-DE',
  'Rehoboth-Beach,-DE',
  'Middletown,-DE',
  'Georgetown,-DE',
  'Millsboro,-DE',
  'Bear,-DE',
  'Hockessin,-DE',
  // VERMONT
  'Burlington,-VT',
  'Rutland,-VT',
  'South-Burlington,-VT',
  'Bennington,-VT',
  'Brattleboro,-VT',
  'Middlebury,-VT',
  'Montpelier,-VT',
  'Williston,-VT',
  'Colchester,-VT',
  'Saint-Albans,-VT',
  'Berlin,-VT',
  'Saint-Johnsbury,-VT',
  // RHODE ISLAND
  'Providence,-RI',
  'Warwick,-RI',
  'Woonsocket,-RI',
  'Newport,-RI',
  'Cranston,-RI',
  'Johnston,-RI',
  'Middletown,-RI',
  'North-Kingstown,-RI',
  'Pawtucket,-RI',
  'East-Providence,-RI',
  'Lincoln,-RI',
  'Smithfield,-RI',
  // ALASKA
  'Anchorage,-AK',
  'Fairbanks,-AK',
  'Juneau,-AK',
  'Wasilla,-AK',
  'Ketchikan,-AK',
  'Sitka,-AK',
  'Palmer,-AK',
  'Soldotna,-AK',
  'Nome,-AK',
  'Kodiak,-AK',
  'Prudhoe-Bay,-AK',
  'Seward,-AK',
  // WYOMING
  'Cheyenne,-WY',
  'Casper,-WY',
  'Gillette,-WY',
  'Rock-Springs,-WY',
  'Laramie,-WY',
  'Jackson,-WY',
  'Sheridan,-WY',
  'Cody,-WY',
  'Rawlins,-WY',
  'Riverton,-WY',
  'Lander,-WY',
  'Evanston,-WY',
]

export default CareerjetCities.sort()
