import './App.css'

import { withAuthentication } from './session'
import { Routes, Route } from 'react-router-dom'
import JobDetailPage from './pages/job-detail'
import AdminsPage from './pages/Admins'
import EmployersPage from './pages/Employers'
import CreateEmployerPage from './pages/Employers/createProfile'
import EditEmployerPage from './pages/Employers/editProfile'
import JobsPage from './pages/Jobs'
import PostJobPage from './pages/Jobs/createJob'
import EditJobPage from './pages/Jobs/editJob'
import EmployerPage from './pages/employer'
import MyTasksPage from './pages/my-tasks-list'
import SettingsPage from './pages/settings'
import PlansPage from './pages/Plans'
import SignPage from './pages/login'
import CreateAdminPage from './pages/Admins/createAdmin'
import 'react-datepicker/dist/react-datepicker.css'
import ResetPasswordPage from './pages/restore-password'
import ScraperPage from './pages/JobScraper'

function App() {
  return (
    <div className="App">
      <Routes>
        <Route index element={<JobsPage />} />
        <Route path="jobs" element={<JobsPage />} />
        <Route path="jobs/:id" element={<EditJobPage />} />
        {/* <Route path="/jobs/:id" element={<JobDetailPage />} /> */}
        <Route path="/job-detail" element={<JobDetailPage />} />

        <Route path="admins" element={<AdminsPage />} />
        <Route path="add-admin" element={<CreateAdminPage />} />

        <Route path="employers" element={<EmployersPage />} />
        <Route path="add-employer" element={<CreateEmployerPage />} />
        <Route path="employers/:id" element={<EditEmployerPage />} />

        <Route path="employer" element={<EmployerPage />} />
        <Route path="my-tasks-list" element={<MyTasksPage />} />
        <Route path="post-job" element={<PostJobPage />} />
        <Route path="settings" element={<SettingsPage />} />
        <Route path="pricing" element={<PlansPage />} />
        <Route path="login" element={<SignPage />} />
        <Route path="job-scraper" element={<ScraperPage />} />
        <Route path="reset-password" element={<ResetPasswordPage />} />
      </Routes>
    </div>
  )
}

export default withAuthentication(App)
