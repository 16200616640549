// requires environment variable
const GCP_PROJECT_ID = process.env.REACT_APP_PROJECT_ID

const Collections = {
  USERS: 'Users',
  JOBS: 'Jobs',
  ACTIVITIES: 'Activities',
  SCRAPER_INSTANCES: 'ScraperInstances',
  PLANS: 'Plans',
}

const projectSettings = {
  'findajobusa-13ec3': {
    defaultBucket: 'findajobusa-13ec3.appspot.com',
    apiEndpoint: 'https://us-central1-findajobusa-13ec3.cloudfunctions.net/api',
    title: 'FindaJob',
  },
}

const apiSendMessage = `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/sendMessage`

const CountryList = ['Canada', 'Puerto Rico', 'United States']

const ContractTypes = ['Full Time', 'Part Time', 'Remote Jobs', 'Freelancer']

const SortTypes = [
  // { key: '', value: 'Select an option' },
  { key: 'most-recent', value: 'Most recent' },
  { key: 'least-recent', value: 'Least recent' },
]

export const ScraperCities = [{ key: 'california', value: 'California' }]

const StatusJobTypes = [
  { key: 'all', value: 'All' },
  { key: 'published', value: 'Published' },
  { key: 'draft', value: 'Draft' },
  // { key: 'expired', value: 'Expired' },
]

const IndustryTypes = [
  'Accounting',
  'Advertising',
  'Agriculture',
  'Analysis',
  'Biological Science',
  'Business Development',
  'Data Science',
  'Design',
  'Engineering',
  'Finance',
  'Graphic Design',
  'Human Resources',
  'Information Services / Technology (IT)',
  'Legal',
  'Management',
  'Marketing',
  'Medical Science',
  'Other',
  'Product Management',
  'Project Management',
  'Public Relations',
  'Quality Assurance',
  'Real Estate / Property Management',
  'Research',
  'Sales',
  'Software Development',
  'Supply Chain / Logistics',
  'Warehousing and Storage',
]

const USA_STATES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District Of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
]

const ShowTypes = [25, 50, 100]

const JobTypes = [
  { key: 'all', value: 'All' },
  { key: 'employer-jobs', value: 'Employer' },
  { key: 'admin-jobs', value: 'Admin' },
  { key: 'scraper-jobs', value: 'Scraper' },
]

export {
  USA_STATES,
  GCP_PROJECT_ID,
  Collections,
  projectSettings,
  apiSendMessage,
  ContractTypes,
  IndustryTypes,
  SortTypes,
  ShowTypes,
  JobTypes,
  CountryList,
  StatusJobTypes,
}

// test push
