import {
  collection,
  deleteDoc,
  doc,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import { Collections } from '../constants/defines'

export const listenScraperInstances = (db, callback) => {
  const q = query(
    collection(db, Collections.SCRAPER_INSTANCES),
    orderBy('createdAt', 'desc'),
  )

  return onSnapshot(q, (snapshot) => {
    const docs = snapshot?.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    callback(docs)
  })
}

export const addScraperInstance = async (payload) => {
  const db = getFirestore()
  const newDocRef = doc(collection(db, Collections.SCRAPER_INSTANCES))
  const id = newDocRef.id
  payload = {
    ...payload,
    id,
  }
  console.log('nanana ')
  console.log(payload)
  await setDoc(newDocRef, payload)
  return id
}

export const updateScraperInstance = async (id, payload) => {
  const db = getFirestore()
  const newDocRef = doc(collection(db, Collections.SCRAPER_INSTANCES), id)
  payload = {
    ...payload,
  }
  await updateDoc(newDocRef, payload)
  return id
}

export const removeScraperInstance = async (id) => {
  const newDocRef = doc(
    collection(getFirestore(), Collections.SCRAPER_INSTANCES),
    id,
  )
  await deleteDoc(newDocRef)
}
