import { Menu } from '@headlessui/react'
import { Link } from 'react-router-dom'
import { useEffect, useState, useContext } from 'react'
import AuthUserContext from '../../session/context'
import FirebaseContext from '../../firebase/context'

export default function Header() {
  const [scroll, setScroll] = useState(0)
  const data = useContext(AuthUserContext)
  const firebaseContext = useContext(FirebaseContext)
  const user = data ? data.user : null

  useEffect(() => {
    document.addEventListener('scroll', () => {
      const scrollCheck = window.scrollY > 100
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck)
      }
    })
  })

  const clickSignOut = (event) => {
    event.preventDefault()
    firebaseContext.doSignOut()
  }

  return (
    <>
      <div style={{ height: '76px' }} />
      <header className={`header sticky-bar ${scroll ? 'stick' : ''}`}>
        <div className="container">
          <div className="main-header">
            <div className="header-left">
              <div className="header-logo">
                <Link className="d-flex" href="/">
                  <img
                    style={{ maxWidth: '10rem' }}
                    alt="FindaJobUSA"
                    src="/assets/imgs/template/logo-small.png"
                  />
                </Link>
              </div>
            </div>

            <div className="header-right">
              <div className="block-signin">
                <div className="member-login">
                  <div className="info-member">
                    {' '}
                    <strong className="color-brand-1">{user?.name}</strong>
                    <Menu
                      as="div"
                      className="dropdown "
                      style={{ cursor: 'pointer' }}
                    >
                      <Menu.Button
                        as="a"
                        className="font-xs color-text-paragraph-2 icon-down"
                      >
                        Super Admin
                      </Menu.Button>
                      <Menu.Items
                        as="ul"
                        className="dropdown-menu dropdown-menu-light dropdown-menu-end show"
                        style={{ right: '0', left: 'auto' }}
                      >
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            aria-label="SignOut"
                            onClick={clickSignOut}
                          >
                            Logout
                          </button>
                        </li>
                      </Menu.Items>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}
