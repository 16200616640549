import CustomSelect from '../../components/filter/CustomSelect'
import React, { useEffect, useState } from 'react'
import { orderBy, where } from 'firebase/firestore'
import { SortTypes } from '../../constants/defines'
import { useDebounce } from '@uidotdev/usehooks'

const JobsFilters = ({ onChange }) => {
  const [sort, setSort] = useState('most-recent')
  const [limit, setLimit] = useState(15)
  const [jobType, setJobType] = useState('all')
  const [status, setStatus] = useState('all')
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 700)

  useDebounce()
  useEffect(() => {
    let filters = []
    filters.push(where('isAdmin', '==', true))

    if (debouncedSearch) {
      filters.push(where('email', '>=', debouncedSearch))
    }

    // SORT
    if (sort === 'most-recent') {
      filters.push(orderBy('createdAt', 'desc'))
    } else if (sort === 'least-recent') {
      filters.push(orderBy('createdAt'))
    }

    onChange(filters)
  }, [sort, limit, jobType, status, debouncedSearch])

  return (
    <div className="grid-toolbar">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          marginBottom: '.5rem',
          justifyContent: 'space-between',
        }}
      >
        <div className="col-3">
          <div className="box-search-user">
            <input
              className="form-control input-search"
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              name="keyword"
              placeholder="Search by email"
            />
          </div>
        </div>

        <div className="box-toolbar">
          <CustomSelect
            onSelect={setSort}
            value={sort}
            items={SortTypes}
            label="Sort"
            width={150}
          />
        </div>
      </div>
    </div>
  )
}

export default JobsFilters
