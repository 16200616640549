import React, { useCallback, useEffect, useState } from 'react'
import { IndustryTypes } from '../../constants/defines'
import { getUser, updateUser } from '../../db/user'
import { getUserJobs } from '../../db/job'
import { toast } from 'react-toastify'

const UserProfileTab = ({ user, onSubmit }) => {
  const [localUser, setLocalUser] = useState(user)
  const onChange = (event) => {
    let { name, value, checked, type } = event.target
    value = type === 'checkbox' ? checked : value
    setLocalUser({ ...user, [name]: value })
  }

  const onSubmitProfile = useCallback(
    (e) => {
      e.preventDefault()
      onSubmit(localUser)
    },
    [localUser],
  )
  return (
    <div className="row form-contact">
      <form onSubmit={onSubmitProfile}>
        <div className="col-lg-6 col-md-12">
          <div className="form-group">
            <label className="font-sm color-text-mutted mb-10">
              Full Name *
            </label>
            <input
              className="form-control"
              type="text"
              name="name"
              value={localUser.name}
              onChange={onChange}
            />
          </div>
          <div className="form-group">
            <label className="font-sm color-text-mutted mb-10">Email *</label>
            <input
              className="form-control"
              type="text"
              name="email"
              value={localUser.email}
              onChange={onChange}
            />
          </div>
          <div className="form-group">
            <label className="font-sm color-text-mutted mb-10">
              Contact number
            </label>
            <input
              className="form-control"
              type="text"
              name="phone"
              value={localUser.phone}
              onChange={onChange}
            />
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label className="font-sm color-text-mutted mb-10">
                  Company
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="company"
                  value={localUser.company}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label className="font-sm color-text-mutted mb-10">
                  Industry
                </label>
                <select
                  className="form-control select-active"
                  required
                  name="industry"
                  value={localUser.industry}
                  onChange={onChange}
                >
                  {IndustryTypes.map((item) => (
                    <option value={item} key={`edit-profile-industry-${item}`}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="font-sm color-text-mutted mb-10">
              Company website
            </label>
            <input
              className="form-control"
              type="url"
              name="web"
              value={localUser.web}
              onChange={onChange}
            />
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label className="font-sm color-text-mutted mb-10">
                  Country
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="country"
                  value={localUser.country}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label className="font-sm color-text-mutted mb-10">State</label>
                <input
                  className="form-control"
                  type="text"
                  name="state"
                  value={localUser.state}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label className="font-sm color-text-mutted mb-10">City</label>
                <input
                  className="form-control"
                  type="text"
                  name="city"
                  value={localUser.city}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label className="font-sm color-text-mutted mb-10">
                  Zip code
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="zip"
                  value={localUser.zip}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
          <div className="border-bottom pt-10 pb-10 mb-30" />
          <h6 className="color-orange mb-20">Change your password</h6>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label className="font-sm color-text-mutted mb-10">
                  Password
                </label>
                <input
                  className="form-control"
                  type="password"
                  defaultValue={123456789}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label className="font-sm color-text-mutted mb-10">
                  Re-Password *
                </label>
                <input
                  className="form-control"
                  type="password"
                  defaultValue={123456789}
                />
              </div>
            </div>
          </div>
          <div className="border-bottom pt-10 pb-10" />
          <div className="box-button mt-15">
            <button className="btn btn-apply-form font-md font-bold">
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default UserProfileTab
